import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import ClockIcon from "../../assets/images/guide/clockIcon.png";
import LangIcon from "../../assets/images/guide/langIcon.png";
import bookIcon from "../../assets/images/guide/bookIcon.png";
import certIcon from "../../assets/images/guide/certIcon.png";
import starsIcon from "../../assets/images/guide/stars.png";
import certificateIcon from "../../assets/images/guide/certificateIcon.png";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const Index = ({ cn = '', item }) => {
  const isMetawheel = item.section === 'Metawheel';
  const isRightPositioned = item.borderPosition === 'right';
  const titleRef = useRef(null);
  const [titleStyle, setTitleStyle] = useState({ color: item.titleColor });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
         if (item.titleColor === '#FBD003') {
          setTitleStyle({ color: item.titleColor });
        } else if (!entry.isIntersecting) {
          setTitleStyle((prevStyle) => ({
            ...prevStyle,
            ...(isRightPositioned ? { marginRight: '250px' } : { marginLeft: '250px' })
          }));
        }
        observer.disconnect();
      },
      { root: null, threshold: 1.0 }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [isRightPositioned, item.titleColor]);

  return ( 
    <div className={`course-card ${cn}`}>
      <div className="course-header">
        <div className="course-title" ref={titleRef} style={titleStyle}>{item.title}</div>
        <div className="course-subtitle" style={{ color: item.titleColor, marginLeft: item.subTitleMargin }}>
          {item.subTitle.split("<br />").map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ))}
        </div>
      </div>
      
      <div className="course-image" style={{ backgroundImage: `url(${item.image})` }}>
        <div className="course-info-overlay-center">
        <div className="course-benefit-details">
        {isMetawheel ? 
              <p>ALL SECTIONS INCLUDED</p> : 
              <>
                <p style={{ textTransform: "uppercase" }}>
                  {item.coreConcept || "6 Core Concept"}
                </p>
                <p style={{ textTransform: "uppercase" }}>
                  {item.uniqueWorkshop || "6 Unique Workshops"}
                </p>
                <p style={{ textTransform: "uppercase" }}>
                  {item.module || "30 Modules"}
                </p>
              </>
            }
            {isMetawheel && (
              <p style={{fontSize:'16px', fontWeight:'normal', marginTop: '10px'}}>
                Complete set of 24 unique set of development guides
              </p>
            )}
        </div>
          
          <div className={`course-benefits ${item.section === 'Metawheel' ? 'metawheel-style' : ''}`}>
            <p style={{ marginTop: "30px" }}>
              <img src={bookIcon} alt="book icon" style={{ display: 'inline-block' }} />
                {item.firstCourseBenefit || 'Join the course to get the companion guide'}
            </p>
            <p>
              {item.section === 'health' ? <img src={bookIcon} alt="book icon" style={{ display: 'inline-block' }} /> : <img src={certIcon} alt="cert icon" style={{ display: 'inline-block' }} />}
              {item.secondCourseBenefit || 'Finish course to get your special certificate'}
            </p>
          </div>       
        </div>
        <div className={`${isRightPositioned ? 'course-info-overlay-right' : 'course-info-overlay-left'}`}>
          <div className="pre-course">
            <h5>{item.type}</h5>
            <p className="course-name">{item.name}</p>
          </div>
          <div className="presenter-rating">
            <p className="presenter">
              <strong>Presenter: </strong>{item.presenter}
            </p>
            <p className="rating" style={{ marginTop: '5px' }}>
              <img src={starsIcon} alt="star icon" style={{ display: 'inline-block' }} />
              {item.section === 'Health' ? '(7 Reviews)' : '(102)'}
            </p>
            {item.description && <p className="description">
              <strong>Description: </strong>{item.description}</p>}
          </div>

          <div>
            <div className="price-section">
              <p className="course-pricing">
              {item.section === 'Health' && 'From'}
                <span className="new-price">
                  ${item.newPrice}
                </span>

                {item.section !== 'Health' && (
                  <span className="old-price">
                    ${item.oldPrice}
                  </span>
                )}
              </p>

              <p><img src={ClockIcon} alt="clock icon" style={{ width: '16px', marginRight: '8px', display: 'inline-block' }} />
                <strong>Duration:</strong>{item.duration}</p>
              <p><img src={LangIcon} alt="language icon" style={{ width: '16px', marginRight: '8px', display: 'inline-block' }} />
                <strong>Language:</strong>{item.language}</p>
              <p className="certificate-icon">
                <img src={certificateIcon} alt="certificate icon" style={{ width: '100px', marginLeft: '10px', display: 'inline-block' }} />
              </p>  
            </div>
            <div className="course-actions2">
              <Link to={item.firstButtonUrl} className="btn add-to-cart">
                {item.firstButtonName || 'ADD TO CART'}
              </Link>
              <a href={item.secondButtonUrl} className="btn join-now">
                {item.secondButtonName || 'JOIN NOW'}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
