import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import sliderImg from "../../assets/images/advance-guide/banner-guide.webp";
import sliderImg1 from "../../assets/images/advance-guide/ban-img1.png";
import sliderImg2 from "../../assets/images/advance-guide/ban-img2.png";
import sliderImg3 from "../../assets/images/advance-guide/ban-img3.png";
import ClusterIcons from '../../components/cluster-icons';
import './AdvanceBannerGuideSlider.scss';
import FiveMen from '../../components/five-men';

const AdvanceBannerGuideSlider = ({updateGuideState}) => {
  const [scrollPage, setOffset] = useState(0);
  const textDot = ['HEALTH', 'Prosperity', 'Inspiration', 'Joy'];
  useEffect(() => {
    window.onscroll = () => {
      setOffset(window.pageYOffset);
    };
  }, []);

  var settings = {}
  const handleClick = (tab) => {
    // Call the function passed from the parent component
    updateGuideState(tab);
    window.location.href = '#guide_workshop_course'
  };
    
  return (
    <>
      <div className="advance-Exercise-slider">
      <ClusterIcons/>
        <Row>
          <Col sm={12} md={12}>
            <Slider {...settings}>
              <div className="slider-conatiner">
                <div className="guide-text">
                  <div className="cente-text1">
                    <div className="line"></div>
                    <h1>Advanced Learning</h1>
                    <p>A progressive learning system of Guides, Workshops and Courses designed to work in concert with our Metawheel App. Allow Metawheel analysis to pinpoint the specific areas of growth that you need most to advance your holistic wellbeing. Fulfil that growth through our human advancement literature and learning material to achieve the greatest Health, Prosperity, Inspiration and Joy in life.</p>
                  </div>
                  <div className="right-img">
                    <span onClick={() => handleClick('GUIDES')}>
                      <img alt="no image" loading="lazy" src={sliderImg1} />
                      <p>GUIDES</p>
                    </span>
                    <span onClick={() => handleClick('WORKSHOPS')}>
                      <img alt="no image" src={sliderImg2} />
                      <p>WORKSHOPS</p>
                    </span>
                    <span onClick={() => handleClick('COURSES')}>
                      <img alt="no image" src={sliderImg3} />
                      <p>COURSES</p>
                    </span>

                  </div>
                </div>
                <img alt="no image" src={sliderImg} className="slider-img" />
              </div>
              <div className="slider-conatiner">
                <div className="guide-text">
                  <div className="cente-text1">
                    <div className="line"></div>
                    <h1>Advanced Learning</h1>
                    <p>A progressive learning system of Guides, Workshops and Courses designed to work in concert with our Metawheel App. Allow Metawheel analysis to pinpoint the specific areas of growth that you need most to advance your holistic wellbeing. Fulfil that growth through our human advancement literature and learning material to achieve the greatest Health, Prosperity, Inspiration and Joy in life.</p>
                  </div>
                  <div className="right-img">
                    <span>
                      <img alt="no image" src={sliderImg1} />
                      <p>GUIDES</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg2} />
                      <p>WORKSHOPS</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg3} />
                      <p>COURSES</p>
                    </span>

                  </div>
                </div>
                <img alt="no image" src={sliderImg} className="slider-img" />
              </div>
              <div className="slider-conatiner">
                <div className="guide-text">
                  <div className="cente-text1">
                    <div className="line"></div>
                    <h1>Advanced Learning</h1>
                    <p>A progressive learning system of Guides, Workshops and Courses designed to work in concert with our Metawheel App. Allow Metawheel analysis to pinpoint the specific areas of growth that you need most to advance your holistic wellbeing. Fulfil that growth through our human advancement literature and learning material to achieve the greatest Health, Prosperity, Inspiration and Joy in life.</p>
                  </div>
                  <div className="right-img">
                    <span>
                      <img alt="no image" src={sliderImg1} />
                      <p>GUIDES</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg2} />
                      <p>WORKSHOPS</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg3} />
                      <p>COURSES</p>
                    </span>

                  </div>
                </div>
                <img alt="no image" src={sliderImg} className="slider-img" />
              </div>              <div className="slider-conatiner">
                <div className="guide-text">
                  <div className="cente-text1">
                    <div className="line"></div>
                    <h1>Advanced Learning</h1>
                    <p>A progressive learning system of Guides, Workshops and Courses designed to work in concert with our Metawheel App. Allow Metawheel analysis to pinpoint the specific areas of growth that you need most to advance your holistic wellbeing. Fulfil that growth through our human advancement literature and learning material to achieve the greatest Health, Prosperity, Inspiration and Joy in life.</p>
                  </div>
                  <div className="right-img">
                    <span>
                      <img alt="no image" src={sliderImg1} />
                      <p>GUIDES</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg2} />
                      <p>WORKSHOPS</p>
                    </span>
                    <span>
                      <img alt="no image" src={sliderImg3} />
                      <p>COURSES</p>
                    </span>

                  </div>
                </div>
                <img alt="no image" src={sliderImg} className="slider-img" />
              </div>


            </Slider>
          </Col>
        </Row>
        {/* <HealthInfoWrapper1 dotGlobal={dotGlobal} /> */}
        {/* <div className="info-wraper">
          <Row>
            <Col sm={12} md={4}>

            </Col>
            <Col sm={12} md={4}>
              <FiveMen></FiveMen>
              <div className="tb-info">
            <img alt="no image" className="nactiveimg"  src={'Cluster-1.png'} />
            <img alt="no image"  className="nactiveimg" src={'Cluster-2.png'} />
            <img alt="no image" className="activeimg" src={`${'Cluster-'}${dotGlobal}.png`} />
            <img alt="no image"  className="nactiveimg" src={'Cluster-3.png'} />
            <img alt="no image"  className="nactiveimg" src={'Cluster-4.png'} />
            </div> 
            </Col>

          </Row>
        </div> */}
      </div>

    </>
  );
}

export default AdvanceBannerGuideSlider;